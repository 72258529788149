<template>
  <div id="inside" style="margin-bottom: 100px">
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-12 text-body px-2 mb-2">
            <button
              class="justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1 text-primary"
              @click="home()"
            >
              <i class="fa fa-chevron-left fa-fw pull-left text-info"></i>
              Voltar
            </button>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row" v-if="cardOffers && !loadOffers">
          <div class="col-12 text-body pr-0">
            <h5 class="text-bold mt-2">Você está negociando</h5>
            <HeaderOptions />
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="!cardOffers && !loadOffers">
      <div class="container">
        <div class="row">
          <div
            class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"
          >
            <i
              class="fa fa-2x fa-exclamation-circle pull-left mr-4 mb-2 text-primary"
            ></i>
            <h4 class="py-4 text-primary">
              No momento não temos uma oferta disponível para você.
            </h4>
          </div>
        </div>
      </div>
      <div class="row pb-5">
        <div
          class="align-items-center justify-content-center col-sm-12 col-12 d-flex col-md-12 col-lg-12 px-4"
        >
          <h6 class="py-2 text-muted">
            Conte com a gente para ter um alívio financeiro! É só selecionar o
            produto e acionar o atendimento que preferir:
          </h6>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-lg-3 px-4">
          <h4 class="py-4">Central de Relacionamento Cartão de Crédito</h4>
          <p>
            <a href="tel:40043600" target="_blank">4004 3600</a> - Capitais e
            regiões metropolitanas.&nbsp;<br /><a
              href="tel:08007277477"
              target="_blank"
              >0800 727 7477</a
            >
            - Demais localidades.&nbsp;<br /><a
              href="tel:551133663216"
              target="_blank"
              >+55 11 3366 3216</a
            >
            - Atendimento no exterior.&nbsp;<br />De segunda a sexta-feira, das
            8h às 18h30.
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">
            Central de Relacionamento Cartão de Crédito Empresarial
          </h4>
          <p>
            <a href="tel:1140043600" target="_blank">4004 3600</a> - Capitais e
            regiões metropolitanas.<br /><a
              href="tel:08007277477"
              target="_blank"
              >0800-727-7477</a
            >
            - Demais localidades.&nbsp;<br />De segunda à sexta das 7h40 às
            19h00.
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">
            Central de Relacionamento Empréstimo e Financiamento
          </h4>
          <p>
            <a href="tel:40045215" target="_blank">4004 5215</a>&nbsp;<br />De
            segunda a sexta-feira, das 8h às 19h (exceto feriados).
          </p>
        </div>
        <div class="p-0 px-4 col-12 col-lg-3">
          <h4 class="py-4">SAC</h4>
          <p>
            <a href="tel:08007272745" target="_blank">0800 727 2745</a
            ><br />Atendimento 24 horas.
          </p>
        </div>
      </div>
    </div>

    <div
      class="container"
      v-if="cardOffers && !loadOffers"
      :style="largura < 500 ? '' : ''"
    >
      <div class="container">
        <div class="card pt-4 pb-4">
          <div class="col-12">
            <h5 class="mb-0 d-flex flex-row align-items-center">
              {{ offerInfo.product }}
            </h5>
            <p class="mb-1" style="font-size: 8pt">
              Contrato: {{ offerInfo.contract }}
            </p>
          </div>
          <hr class="mr-3 ml-3" />
          <div class="d-flex col-lg-12 col-12">
            <p class="mb-0 mr-2" style="font-size: 10pt">
              Valor original da dívida:
            </p>
            <p class="mb-0" style="font-weight: bold">
              {{ formatFloatToReal(parseFloat(offerInfo.originalValue, 10)) }}
            </p>
          </div>
          <div class="d-flex col-lg-12 col-12">
            <p class="mb-0 mr-2" style="font-size: 10pt">
              Vencimento original da dívida:
            </p>
            <p class="mb-0" style="font-weight: bold">
              {{ formatData(offerInfo.dueDate, "YYYY-MM-DD", "L") }}
            </p>
          </div>
          <div class="d-flex col-lg-12 col-12">
            <p class="mb-0 mr-2" style="font-size: 10pt">
              Valor atualizado da dívida:
            </p>
            <p class="mb-0" style="font-weight: bold">
              R$
              {{ formatFloatToReal(parseFloat(offerInfo.paymentValue, 10)) }}
            </p>
          </div>
          <div class="d-flex col-lg-12 col-12">
            <p class="mb-0 mr-2" style="font-size: 10pt">Dias de atraso:</p>
            <p class="mb-0" style="font-weight: bold">
              {{ offerInfo.daysDelay }}
            </p>
          </div>
        </div>
      </div>

      <div class="container" v-if="cardOffers && !loadOffers">
        <!-- <div class="col" v-if="cardOffers && !loadOffers"> -->
        <h5 class="mt-4" style="font-style: bold">Opções de pagamento</h5>
        <HeaderOptions />
        <!-- </div> -->
      </div>

      <div class="container" v-if="cardOffers && !loadOffers">
        <div class="row">
          <div
            class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2"
            v-for="(value, key, index) in cardOffers.paymentOptions"
            :key="`${key}-${index}`"
          >
            <div
              class="card p-0"
              :style="largura < 500 ? '' : 'min-height: 258px !important;'"
            >
              <div class="banner" v-if="value.installmentsNumber === 1">
                <!-- <span class="icon">🔥</span> -->
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 12 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 5px; margin-bottom: 4px;"
                >
                  <path
                    d="M3.16172 6.41295C4.15428 4.93822 5.09363 3.53429 5.20824 1.7642C5.23484 1.35961 5.20005 0.950976 5.21438 0.546385C5.22052 0.360273 5.09568 0.101335 5.35354 0.0123249C5.54387 -0.0524096 5.66461 0.153932 5.78945 0.271263C7.14834 1.55179 8.44173 2.89099 9.54685 4.39809C10.8321 6.14997 11.8471 8.02727 11.9843 10.2303C12.1725 13.2283 10.6745 16.2384 7.38982 16.8838C3.29884 17.6889 -0.444237 14.1932 0.0428338 10.0785C0.255671 8.28621 0.904417 6.69617 1.96042 5.24976C2.07707 5.08994 2.17326 4.83505 2.39837 4.86944C2.63168 4.90383 2.6194 5.17693 2.68284 5.36506C2.7647 5.6058 2.8527 5.84248 2.94889 6.07714C2.98572 6.16615 3.05121 6.24303 3.16172 6.41295ZM5.92861 8.01513C5.94908 9.21879 5.57252 10.0442 5.04656 10.8109C4.9381 10.9686 4.78666 11.3409 4.5554 10.9808C4.1461 10.3415 4.03559 10.8473 3.86777 11.1204C3.55465 11.6261 3.37251 12.1885 3.31521 12.7711C3.19242 13.999 3.58331 15.0328 4.64954 15.7388C5.64005 16.3942 6.68582 16.4286 7.70089 15.8197C8.75894 15.1845 9.12732 14.1427 9.02704 13.0017C8.84694 10.9403 7.49215 9.54651 5.92861 8.01513Z"
                    fill="#2C3DA8"
                  />
                </svg>

                <span class="banner-text">MELHOR OFERTA!</span>
              </div>

              <div class="card-body p-4 border-top-0 py-2">
                <!-- Conteúdo do Cartão -->
                <div class="row">
                  <div class="col-lg-12 col-12">
                    <h5
                      class="text-bold mb-0"
                      style="color: black"
                      v-if="value.installmentsNumber === 1"
                    >
                      Pagamento à vista
                    </h5>
                    <h5
                      class="text-bold"
                      style="color: black"
                      v-if="value.installmentsNumber !== 1"
                    >
                      Pagamento parcelado
                    </h5>
                  </div>
                </div>

                <!-- PARA MOBILE -->
                <div class="row mt-3" v-if="largura < 500">
                  <div
                    class="d-flex flex-column col-7"
                    v-if="value.discount > 0"
                  >
                    <div
                      class="text-info price-line"
                      v-if="
                        value.discount > 0 && value.installmentsNumber === 1
                      "
                    >
                      <p class="mr-2 mb-0">De</p>
                      <span class="line-through">
                        {{
                          formatFloatToReal(
                            parseFloat(offerInfo.paymentValue, 10)
                          )
                        }}
                      </span>
                      <p class="ml-2 mb-0">por</p>
                    </div>
                    <!-- :style="largura > 500 && value.discount > 0 && value.installmentValue.toFixed(1).length > 6 ? 'font-size: 9pt;' : ''" -->
                    <div
                      class="d-flex mb-0 price-current"
                      v-if="value.installmentsNumber === 1"
                    >
                      <!-- AAAA{{ value.installmentValue.toFixed(1).length }} -->
                      {{
                        formatFloatToReal(
                          parseFloat(value.installmentValue, 10)
                        )
                      }}
                      <p
                        class="mt-2 ml-1 mb-0"
                        :style="
                          largura < 500
                            ? 'font-weight: bold; font-size: 10pt'
                            : 'font-weight: bold; font-size: 9pt;'
                        "
                      >
                        à vista
                      </p>
                    </div>
                    <div class="mb-0" v-if="value.installmentsNumber !== 1">
                      <p class="mr-2 mb-0">Entrada de</p>
                      <p class="price-current mb-0" style="font-size: 16pt">
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </p>
                      <p class="mr-2 mb-0">
                        + {{ value.installmentsNumber - 1 }}x de
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="d-flex flex-column col-8"
                    v-if="value.discount < 0"
                  >
                    <div class="text-info price-line" v-if="value.discount > 0">
                      <p class="mr-2 mb-0">De</p>
                      <span class="line-through">
                        {{
                          formatFloatToReal(
                            parseFloat(offerInfo.paymentValue, 10)
                          )
                        }}
                      </span>
                      <p class="ml-2 mb-0">por</p>
                    </div>
                    <div
                      class="d-flex mb-0 price-current"
                      v-if="value.installmentsNumber === 1"
                    >
                      {{
                        formatFloatToReal(
                          parseFloat(value.installmentValue, 10)
                        )
                      }}
                      <p class="mt-1 ml-2 mb-0" style="font-weight: bold">
                        à vista
                      </p>
                    </div>
                    <div class="mb-0" v-if="value.installmentsNumber !== 1">
                      <p class="mr-2 mb-0">Entrada de</p>
                      <p class="price-current mb-0" style="font-size: 16pt">
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </p>
                      <p class="mr-2 mb-0">
                        + {{ value.installmentsNumber - 1 }}x de
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-5 text-right discount"
                    v-if="value.discount > 0 && value.installmentsNumber === 1"
                  >
                    <p class="mb-0" style="font-size: 8pt">Desconto de até</p>
                    <span class="discount-value">{{ value.discount }}%</span>
                  </div>
                  <div
                    class="col-5 text-right discount"
                    v-if="value.discount > 0 && value.installmentsNumber !== 1"
                  >
                    <p class="mb-0 mt-4 mr-2">Desconto de</p>
                    <span class="discount-value">{{ value.discount }}%</span>
                  </div>
                </div>

                <!-- PARA DESKTOP -->
                <div class="row mt-3" v-if="largura > 500">
                  <div class="d-flex flex-column col-12">
                    <div
                      class="text-info price-line d-flex align-items-center"
                      style="margin-bottom: -15px"
                      v-if="
                        value.discount > 0 && value.installmentsNumber === 1
                      "
                    >
                      <p class="mr-2 mb-0">De</p>
                      <span class="line-through">
                        {{
                          formatFloatToReal(
                            parseFloat(offerInfo.paymentValue, 10)
                          )
                        }}
                      </span>
                      <p class="ml-2 mb-0">por</p>
                    </div>

                    <div
                      class="d-flex align-items-center mb-0 price-current"
                      v-if="
                        value.discount > 0 && value.installmentsNumber === 1
                      "
                    >
                      {{
                        formatFloatToReal(
                          parseFloat(value.installmentValue, 10)
                        )
                      }}
                      <p
                        class="ml-1 mb-0"
                        style="font-weight: bold; font-size: 8pt"
                      >
                        à vista
                      </p>
                      <div class="discount-badge ml-auto">
                        <p
                          class="mb-0"
                          style="font-size: 8pt; font-weight: 500; color: black"
                        >
                          Desconto de até
                        </p>
                        <span
                          class="discount-value"
                          style="font-weight: 500; font-size: 12pt"
                          >{{
                            value.discount.toFixed(2).replace(".", ",")
                          }}%</span
                        >
                      </div>
                    </div>

                    <div
                      class="mb-0"
                      v-if="value.installmentsNumber !== 1"
                      style="justify-content: space-between"
                    >
                      <p class="mr-2 mb-0">
                        Entrada de

                        <span
                          class="float-right"
                          style="font-size: 9pt; font-weight: 500"
                          >Desconto de</span
                        >
                      </p>
                      <div style="align-items: center">
                        <p class="price-current mb-0" style="font-size: 16pt">
                          {{
                            formatFloatToReal(
                              parseFloat(value.installmentValue, 10)
                            )
                          }}
                          <span
                            class="discount-value float-right mt-0"
                            style="font-weight: 500; font-size: 12pt"
                            >{{ value.discount }}%</span
                          >
                        </p>
                        <p class="mr-2 pt-0 mb-0">
                          + {{ value.installmentsNumber - 1 }}x de
                          {{
                            formatFloatToReal(
                              parseFloat(value.installmentValue, 10)
                            )
                          }}
                        </p>
                      </div>
                    </div>

                    <!-- <div
                    class="text-right discount"
                    v-if="value.discount > 0 && value.installmentsNumber === 1"
                  >
                    <p class="mb-0" style="font-size: 8pt">Desconto de até</p>
                    <span class="discount-value">{{ value.discount }}%</span>
                  </div> -->
                    <!-- <div
                    class="text-right discount"
                    v-if="value.discount > 0 && value.installmentsNumber !== 1"
                  >
                    <p class="mb-0 mt-4">Desconto de</p>
                    <span class="discount-value">{{ value.discount }}%</span>
                  </div> -->

                    <!-- <div
                    class="text-right discount"
                    v-if="value.discount > 0 && value.installmentsNumber === 1"
                  >
                    <p class="mb-0" style="font-size: 8pt">Desconto de até</p>
                    <span class="discount-value">{{ value.discount }}%</span>
                  </div>
                  <div
                    class="text-right discount"
                    v-if="value.discount > 0 && value.installmentsNumber !== 1"
                  >
                    <p class="mb-0 mt-4">Desconto de</p>
                    <span class="discount-value">{{ value.discount }}%</span>
                  </div> -->

                    <div
                      class="d-flex flex-column col-12"
                      v-if="value.discount < 0"
                    >
                      <div
                        class="text-info price-line"
                        v-if="value.discount > 0"
                      >
                        <p class="mr-2 mb-0">De</p>
                        <span class="line-through">
                          {{
                            formatFloatToReal(
                              parseFloat(offerInfo.paymentValue, 10)
                            )
                          }}
                        </span>
                        <p class="ml-2 mb-0">por</p>
                      </div>
                      <div
                        class="d-flex mb-0 price-current"
                        v-if="value.installmentsNumber === 1"
                      >
                        {{
                          formatFloatToReal(
                            parseFloat(value.installmentValue, 10)
                          )
                        }}
                        <p class="mt-1 ml-2 mb-0" style="font-weight: bold">
                          à vista
                        </p>
                      </div>
                      <div class="mb-0" v-if="value.installmentsNumber !== 1">
                        <p class="mr-2 mb-0">Entrada de</p>
                        <p class="price-current mb-0" style="font-size: 16pt">
                          {{
                            formatFloatToReal(
                              parseFloat(value.installmentValue, 10)
                            )
                          }}
                        </p>
                        <p class="mr-2 mb-0">
                          + {{ value.installmentsNumber - 1 }}x de
                          {{
                            formatFloatToReal(
                              parseFloat(value.installmentValue, 10)
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-1" v-if="value.installmentsNumber === 1">
                  <div
                    class="d-flex mt-2 col-12 col-lg-12 justify-content-center"
                    v-if="value.installmentsNumber === 1"
                  >
                    <p class="mb-1 mr-1">Vencimento:</p>
                    <b
                      ><p style="font-size: 10pt">
                        {{ formatData(cardOffers.dueDate, "YYYY-MM-DD", "L") }}
                      </p></b
                    >
                  </div>
                </div>
                <div
                  class="row pb-1 mt-3"
                  v-if="value.installmentsNumber !== 1"
                >
                  <div
                    class="d-flex mt-2 col-12 col-lg-12 justify-content-center"
                    v-if="value.installmentsNumber !== 1"
                  >
                    <p class="mb-1 mr-1">Vencimento da entrada:</p>
                    <b
                      ><p style="font-size: 10pt">
                        {{ formatData(cardOffers.dueDate, "YYYY-MM-DD", "L") }}
                      </p></b
                    >
                  </div>
                </div>

                <!-- Botão de ação -->
                <div class="row pb-2 mt-0">
                  <div class="col-xs-8 col-lg-12 col-12 text-center">
                    <button
                      class="btn btn-primary p-3"
                      style="width: 100%; border-radius: 20px"
                      @click="resumoAcordo(value)"
                      v-if="value.installmentsNumber === 1"
                      :disabled="
                        !isEmptyObject(selectedOption) || loadingMoreOptions
                      "
                    >
                      PAGAR À VISTA
                    </button>
                    <button
                      class="btn mb-2 btn-primary p-3"
                      style="width: 100%; border-radius: 20px"
                      @click="resumoAcordo(value)"
                      v-else
                      :disabled="
                        !isEmptyObject(selectedOption) || loadingMoreOptions
                      "
                    >
                      PAGAR PARCELADO EM {{ value.installmentsNumber }}X
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadOffers"></div>
    </div>
    <div class="container" v-if="loadOffers">
      <ProgressLoading v-bind:loading="loadOffers" />
    </div>
    <div class="container" v-if="cardOffers && !loadOffers">
      <!-- MOBILE -->
      <div
        class="row border-top my-3 mx-auto border-info py-4"
        v-if="largura < 500"
      >
        <div class="col-lg-4 my-3">
          <h5 class="mt-3">Ou escolha outra opção parcelada:</h5>
        </div>
        <div class="col-lg-4 mt-0">
          <div class="form-group">
            <div class="" v-if="otherOptionsInstallment && !loadOffers">
              <!-- <select
                id="ParcelasSelect"
                class="form-control mt-0"
                @change="getValue($event.target.value)"
              >
                <option disabled class="select-header">
                  <span class="option-column">Entrada</span>
                  <span class="option-column">Valor Parcela</span>
                  <span class="option-column">Desconto</span>
                </option>
                <option
                  v-for="(
                    value, key, index
                  ) in selectOtherOffers.paymentOptions"
                  :key="`${key}-${index}`"
                  :value="JSON.stringify(value)"
                  class="select-option"
                >
                  <span class="option-column">{{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}</span>
                  <span class="option-column"
                    >+ {{ value.installmentsNumber - 1 }}x de
                    {{
                      formatFloatToReal(parseFloat(value.installmentValue, 10))
                    }}</span
                  >
                  <span class="option-column"
                    >{{ value.discount.toFixed(2).replace(".", ",") }}%</span
                  >
                  {{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}
                  &nbsp;&nbsp;&nbsp;&nbsp; + {{ value.installmentsNumber - 1 }}x
                  de
                  {{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}
                  &nbsp;&nbsp;&nbsp;&nbsp;

                  <span
                    class="discount-value text-right"
                    v-if="value.discount > 0"
                    >{{ value.discount.toFixed(2).replace(".", ",") }}%</span
                  >
                </option>
                <option selected>Selecionar</option>
                <option v-if="selectOtherOffers.paymentOptions.length < 1">
                  Não existem mais opções disponíveis
                </option>
              </select> -->

              <!-- SELECT ATUAL -->
              <div
                class="custom-select-no-arrow"
                @click="toggleDropdown"
                :class="{ 'disabled-select': loadingMoreOptions }"
              >
                <div class="selected-option">
                  {{ selectedOptionText || "Selecione" }}
                </div>
                <ul v-if="dropdownOpen" class="options-list" id="listOptions">
                  <li class="options-header">
                    <span class="option-column">Entrada</span>
                    <span class="option-column">Valor Parcela</span>
                    <span class="option-column">Desconto</span>
                  </li>
                  <li
                    v-for="(
                      value, index
                    ) in otherOptionsInstallment.paymentOptions"
                    :key="index"
                    @click="selectOption(value)"
                    class="options-item"
                    :class="{ selected: selectedOption === value }"
                  >
                    <span class="option-column">{{
                      formatFloatToReal(value.installmentValue)
                    }}</span>
                    <span class="option-column"
                      >+ {{ value.installmentsNumber - 1 }}x de
                      {{ formatFloatToReal(value.installmentValue) }}</span
                    >
                    <span class="option-column" v-if="value.discount > 0"
                      >{{ value.discount.toFixed(2).replace(".", ",") }}%</span
                    >
                    <span
                      class="option-column"
                      v-if="value.discount <= 0"
                    ></span>
                  </li>
                  <li class="options-item">
                    <span class="option-column" @click="loadMoreOptions()">
                      Carregar mais opções
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div
              class="form-group"
              v-if="otherOptionsInstallment && !loadOffers"
            >
              <select
                id="ParcelasSelectPst"
                class="form-control mt-2"
                @change="getValue($event.target.value)"
              >
                <option
                  v-for="(
                    value, key, index
                  ) in otherOptionsInstallment.paymentOptions"
                  :key="`${key}-${index}`"
                  :value="JSON.stringify(value)"
                >
                  {{ value.installmentsNumber }}x
                  {{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}
                </option>
                <option v-if="!newOffersLoaded">Carregar mais opções</option>
                <option
                  v-if="
                    newOffersLoaded &&
                    !othersOptionsLoaded
                  "
                >
                  Outras opções de negociação
                </option>
                <option v-if="otherOptionsInstallment.paymentOptions.length < 1">
                  Não existem mais opções disponíveis
                </option>
              </select>
            </div> -->
          </div>
        </div>
        <div class="my-3 text-center col-lg-3">
          <button
            class="btn mb-2 btn-primary p-3"
            style="width: 100%; border-radius: 20px"
            :disabled="
              loadOffers || loadingMoreOptions || isEmptyObject(selectedOption)
            "
            @click="resumoAcordoConfirmar(null, false)"
          >
            Confirmar
          </button>
        </div>
        <div class="mt-3 m-auto">
          <a
            @click="resumoAcordo(cardOffers.paymentOptions[0])"
            style="color: #0046c0; text-decoration: underline; cursor: pointer"
            v-if="!loadingMoreOptions"
            >Quero a melhor oferta</a
          >
        </div>
        <div class="container" v-if="loadingMoreOptions">
          <ProgressLoading v-bind:loading="loadingMoreOptions" />
        </div>
      </div>

      <!-- DESKTOP -->
      <div
        class="row border-top border-bottom my-3 mx-auto border-info py-4"
        style="align-items: center"
        v-if="largura > 500"
      >
        <div class="col-lg-4">
          <h5>Ou escolha outra opção parcelada:</h5>
        </div>
        <div class="my-3 text-center col-lg-4">
          <div class="">
            <div class="" v-if="otherOptionsInstallment && !loadOffers">
              <!-- <select
                id="ParcelasSelect"
                class="form-control mt-0"
                @change="getValue($event.target.value)"
              >
                <option disabled class="select-header">
                  Entrada
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Valor Parcela
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Desconto
                </option>
                <option
                  v-for="(
                    value, key, index
                  ) in selectOtherOffers.paymentOptions"
                  :key="`${key}-${index}`"
                  :value="JSON.stringify(value)"
                  class="select-option"
                >
                  {{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}
                  &nbsp;&nbsp;&nbsp;&nbsp; + {{ value.installmentsNumber - 1 }}x
                  de
                  {{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}
                  &nbsp;&nbsp;&nbsp;&nbsp;

                  <span
                    class="discount-value text-right"
                    v-if="value.discount > 0"
                    >{{ value.discount.toFixed(2).replace(".", ",") }}%</span
                  >
                </option>
                <option selected>Selecionar</option>
                <option v-if="selectOtherOffers.paymentOptions.length < 1">
                  Não existem mais opções disponíveis
                </option>
              </select> -->

              <!-- SELECT ATUAL -->
              <div
                class="custom-select-no-arrow"
                @click="toggleDropdown"
                :class="{ 'disabled-select': loadingMoreOptions }"
              >
                <div class="selected-option">
                  {{ selectedOptionText || "Selecione" }}
                </div>
                <ul v-if="dropdownOpen" class="options-list" id="listOptions">
                  <li class="options-header">
                    <span class="option-column">Entrada</span>
                    <span class="option-column">Valor Parcela</span>
                    <span class="option-column">Desconto</span>
                  </li>
                  <li
                    v-for="(
                      value, index
                    ) in otherOptionsInstallment.paymentOptions"
                    :key="index"
                    @click="selectOption(value)"
                    class="options-item"
                    :class="{ selected: selectedOption === value }"
                  >
                    <span class="option-column">{{
                      formatFloatToReal(value.installmentValue)
                    }}</span>
                    <span class="option-column"
                      >+ {{ value.installmentsNumber - 1 }}x de
                      {{ formatFloatToReal(value.installmentValue) }}</span
                    >
                    <span class="option-column" v-if="value.discount > 0"
                      >{{ value.discount.toFixed(2).replace(".", ",") }}%</span
                    >
                    <span
                      class="option-column"
                      v-if="value.discount <= 0"
                    ></span>
                  </li>
                  <li class="options-item">
                    <span class="option-column" @click="loadMoreOptions()">
                      Carregar mais opções
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div
              class="form-group"
              v-if="otherOptionsInstallment && !loadOffers"
            >
              <select
                id="ParcelasSelectPst"
                class="form-control mt-2"
                @change="getValue($event.target.value)"
              >
                <option
                  v-for="(
                    value, key, index
                  ) in otherOptionsInstallment.paymentOptions"
                  :key="`${key}-${index}`"
                  :value="JSON.stringify(value)"
                >
                  {{ value.installmentsNumber }}x
                  {{
                    formatFloatToReal(parseFloat(value.installmentValue, 10))
                  }}
                </option>
                <option v-if="!newOffersLoaded">Carregar mais opções</option>
                <option
                  v-if="
                    negociarPagamentoTotal &&
                    newOffersLoaded &&
                    !othersOptionsLoaded
                  "
                >
                  Outras opções de negociação
                </option>
                <option v-if="otherOptionsInstallment.paymentOptions.length < 1">
                  Não existem mais opções disponíveis
                </option>
              </select>
            </div> -->
          </div>
        </div>
        <div class="my-3 text-center col-lg-4">
          <button
            class="btn mb-2 btn-primary p-3"
            style="width: 100%; border-radius: 20px"
            :disabled="
              otherOptionsInstallment.paymentOptions.length < 1 ||
              selectedOption === 'Selecione outra opção' ||
              isEmptyObject(selectedOption) ||
              loadingMoreOptions
            "
            @click="resumoAcordoConfirmar(null)"
          >
            Confirmar
          </button>
        </div>
        <div class="mt-3 m-auto">
          <a
            @click="resumoAcordo(cardOffers.paymentOptions[0])"
            style="color: #0046c0; text-decoration: underline; cursor: pointer"
            v-if="!loadingMoreOptions"
            >Quero a melhor oferta</a
          >
        </div>
        <div class="container" v-if="loadingMoreOptions">
          <ProgressLoading v-bind:loading="loadingMoreOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
  truncateString,
  formatFloatToReal,
  formatStringToLowerCase,
  isEmptyObject,
} from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import HeaderOptions from "../components/HeaderMenu.vue";
import { VMoney } from "v-money";
import { initializeGlobals } from "@/global";

export default {
  name: "propostas-contencioso",
  components: {
    ProgressLoading,
    HeaderOptions,
  },
  directives: {
    money: VMoney,
  },
  data() {
    return {
      form: {
        offerSelected: null,
      },
      loadOffers: false,
      loadOffersWithEntry: false,
      offers: [],
      offerInfo: [],
      largura: null,
      installment: {},
      loadSetAgreement: false,
      cardOffers: null,
      otherOptionsInstallment: null,
      newOffersLoaded: false,
      othersOptionsLoaded: false,
      selectedOption: {},
      selectedOptionEntryValue: {},
      paymentOptionsId: [],
      entryValue: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      responseWithEntryValue: [],
      entryValueFinal: null,
      loadingMoreOptions: false,
      lastOptionCalled: null,
      count: 0,
      currentOfferInfo: {},
      alreadyCalled: false,
      userDataStorage: {},
      pageLocation: null,
      protocol: null,
      path: null,
      host: null,
      userAdm: {},
      ipUser: null,
      dropdownOpen: false,
      selectedOptionText: "Selecione",
    };
  },
  methods: {
    formatValue,
    formatData,
    truncateString,
    formatFloatToReal,
    formatStringToLowerCase,
    isEmptyObject,

    ...mapActions("meusContratos", ["ActionGetOffersPortoSeguro"]),

    back() {
      this.loadParcelamentoFatura();
    },

    async loadParcelamentoFatura() {
      this.loadOffers = true;

      try {
        var result = await this.ActionGetOffersPortoSeguro({
          clientCode: this.offerInfo.clientCode,
          credor: this.offerInfo.credor,
          installmentsOptions: [1, 2, 3, 4, 5, 6, 7, 8, 12, 60],
          agreementType: "ParcelamentoRecupera",
          contract: this.offerInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem("tag"),
          daysDelay: this.offerInfo.daysDelay,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
          paymentValue: this.offerInfo.paymentValue,
        });

        const paymentOptionCode = result.data.results.paymentOptionCode;
        let obj = {};
        obj[paymentOptionCode] = [1, 2, 3, 4, 5, 6, 7, 8, 12, 60];
        this.paymentOptionsId.push(obj);

        // this.cardOffers = result.data.results;

        const credor = localStorage.getItem("credor");

        const filteredOptions = result.data.results.paymentOptions.filter(
          (option) => {
            let installments = [];
            if (credor == 33) {
              installments = [1, 3, 6, 12];
            } else {
              installments = [1, 6, 12, 60];
            }
            return installments.includes(option.installmentsNumber);
          }
        );

        this.cardOffers = {
          ...result.data.results,
          paymentOptions: filteredOptions,
        };

        const filteredOptionsOther = result.data.results.paymentOptions.filter(
          (option) => {
            let installments = [];
            if (credor == 33) {
              installments = [1, 3, 6, 12];
            } else {
              installments = [1, 6, 12, 60];
            }
            return !installments.includes(option.installmentsNumber);
          }
        );

        this.otherOptionsInstallment = {
          ...result.data.results,
          paymentOptions: filteredOptionsOther,
        };

        // this.selectedOption = this.otherOptionsInstallment.paymentOptions[0];
        this.selectedOptionEntryValue =
          this.otherOptionsInstallment.paymentOptions[0];
      } catch (error) {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          position: "top right",
          title: "Erro",
          message:
            "Não foi possível buscar ofertas, tente novamente mais tarde!",
        });
        this.eventCallbackNegociarFailureGTM(error);
        // this.$router.push({
        //   name: "dashboard",
        // });
      } finally {
        this.loadOffers = false;
      }
    },

    async loadMoreOptions() {
      //eslint-disable-next-line
      // debugger;

      if (this.alreadyCalled) {
        return;
      }

      this.alreadyCalled = true;
      this.loadingMoreOptions = true;
      this.offerInfo = this.$route.params.offerInfo;
      let array = [9, 10, 11, 13, 14];

      if (this.count > 0) {
        array = [];
        array.push(this.lastOptionCalled);
        for (let index = 1; index <= 4; index++) {
          this.lastOptionCalled += 1;
          array.push(this.lastOptionCalled);
        }
      } else {
        array = [9, 10, 11, 13, 14];
      }

      try {
        var result = await this.ActionGetOffersPortoSeguro({
          clientCode: this.offerInfo.clientCode,
          credor: this.offerInfo.credor,
          installmentsOptions: array,
          agreementType: "ParcelamentoRecupera",
          contract: this.offerInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem("tag"),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
          paymentValue: this.offerInfo.paymentValue,
        });

        const paymentOptionCode = result.data.results.paymentOptionCode;
        let obj = {};
        obj[paymentOptionCode] = array;
        this.paymentOptionsId.push(obj);

        const filteredOptions = result.data.results.paymentOptions.filter(
          (option) => {
            const installments = array;
            return installments.includes(option.installmentsNumber);
          }
        );

        filteredOptions.map((value) => {
          const installment =
            this.otherOptionsInstallment.paymentOptions.filter((option) => {
              return option.installmentsNumber === value.installmentsNumber;
            });

          if (installment.length < 1) {
            this.otherOptionsInstallment.paymentOptions.push(value);
          }
        });

        this.otherOptionsInstallment = {
          ...result.data.results,
          paymentOptions: this.otherOptionsInstallment.paymentOptions,
        };

        // this.selectedOption = this.otherOptionsInstallment.paymentOptions[0];
        this.loadingMoreOptions = false;
        this.toggleDropdown();
        this.count += 1;
        this.lastOptionCalled = array[array.length - 1];
        // this.goToSelectOptions();
        this.alreadyCalled = false;
      } catch (error) {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          position: "top right",
          title: "Erro",
          message:
            "Não foi possível buscar ofertas, tente novamente mais tarde!",
        });
        this.back();
      } finally {
        this.loadingMoreOptions = false;
      }
    },

    getValue(selectedValue) {
      if (selectedValue === "Carregar mais opções") {
        this.loadMoreOptions();
      } else {
        const value = JSON.parse(selectedValue);
        this.eventSelecionarOutrasOpcoesNegociacaoGTM(value);
        return (this.selectedOption = value);
      }
    },

    getValueEntry(selectedValue) {
      const value = JSON.parse(selectedValue);
      return (this.selectedOptionEntryValue = value);
    },

    async toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDivOptions() {
      var options = document.getElementById("listOptions");
      if (options) {
        options.style.display = "none";
      }
    },
    async selectOption(value) {
      if (this.selectedOption === value) {
        this.selectedOption = {};
        this.selectedOptionText = "Selecionar";
      } else {
        // Caso contrário, marque-a como selecionada
        this.selectedOptionText = `${formatFloatToReal(
          value.installmentValue
        )} + ${value.installmentsNumber - 1}x de ${formatFloatToReal(
          value.installmentValue
        )} ${
          value.discount > 0
            ? value.discount.toFixed(2).replace(".", ",") + "%"
            : ""
        }`;
        this.selectedOption = value;
      }
      // Fecha o dropdown após a seleção
      this.closeDivOptions();
      // this.dropdownOpen = false;
    },

    async resumoAcordo(offer, isWithEntryValue) {
      // this.eventClickNegociarGTM(offer);

      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/resumoAcordo",
        "resumo-acordo"
      );
      this.updateCustomData();

      this.$router.push({
        name: "resumoAcordo",
        params: {
          offerInfo: {
            clientCode: this.offerInfo.clientCode,
            credor: this.offerInfo.credor,
            product: this.offerInfo.product,
            dueDate: this.cardOffers.dueDate,
            contract: this.offerInfo.contract,
            paymentOptionCode: this.cardOffers.paymentOptionCode,
            paymentOptionsCodeIds: this.paymentOptionsId,
            allowRenegociation: this.offerInfo.allowRenegociation,
            paymentValue: this.offerInfo.paymentValue,
            originalValue: this.offerInfo.originalValue,
            entryValue: isWithEntryValue ? this.entryValueFinal : 0,
            agreementType: "ParcelamentoRecupera",
            daysDelay: this.offerInfo.daysDelay,
            ...offer,
          },
          fromProposals: true,
          from: "propostasContencioso",
          isWithEntryValue: isWithEntryValue,
        },
      });
    },

    async resumoAcordoConfirmar(offer, isWithEntryValue) {
      this.eventClickConfirmarGTM();

      offer = this.selectedOption;


      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard/resumoAcordo",
        "resumo-acordo"
      );
      this.updateCustomData();
      this.$router.push({
        name: "resumoAcordo",
        params: {
          offerInfo: {
            clientCode: this.offerInfo.clientCode,
            credor: this.offerInfo.credor,
            product: this.offerInfo.product,
            dueDate: this.cardOffers.dueDate,
            contract: this.offerInfo.contract,
            paymentOptionCode: this.cardOffers.paymentOptionCode,
            paymentOptionsCodeIds: this.paymentOptionsId,
            allowRenegociation: this.offerInfo.allowRenegociation,
            paymentValue: this.offerInfo.paymentValue,
            originalValue: this.offerInfo.originalValue,
            daysDelay: this.offerInfo.daysDelay,
            entryValue: isWithEntryValue ? this.entryValueFinal : 0,
            agreementType: "ParcelamentoRecupera",
            ...offer,
          },
          fromProposals: true,
          from: "propostasContencioso",
          isWithEntryValue: isWithEntryValue,
        },
      });
    },

    home() {
      this.eventClickArrowToBackGTM();
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard",
        "Dashboard"
      );
      this.updateCustomData();
      this.$router.push({
        name: "dashboard",
      });
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
        cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
        id: id, // Substitua pelo ID real
        page_location: pageLocation,
        pageName: pageName,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickArrowToBackGTM() {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:button:voltar";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventClickNegociarGTM(offer) {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:button:negociar";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: this.buildPayloadOfferChoose(offer),
        product_identify: this.offerInfo.contract,
        product_category: this.offerInfo.product,
        product_user_profile: "titular",
        brand: "porto",
        product: this.offerInfo.product,
        vertical: "bank",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    buildPayloadOfferChoose(offer) {
      var formatedPayload = null;
      if (offer.installmentsNumber === 1) {
        formatedPayload = "pagamento-a-vista";
      } else {
        formatedPayload = `parcelado-em-${offer.installmentsNumber}-vezes`;
      }
      return formatedPayload;
    },

    buildPayloadOfferChooseOtherOptionsOfNegociation(offer) {
      var formatedPayload = null;
      formatedPayload = `${
        offer.installmentsNumber
      }x (entrada: ${this.formatFloatToReal(
        parseFloat(offer.inputValue, 10)
      )} + ${offer.installmentsNumber - 1}x ${this.formatFloatToReal(
        parseFloat(offer.installmentValue, 10)
      )}) total com desc. ${this.formatFloatToReal(
        parseFloat(offer.value, 10)
      )}`;

      return truncateString(formatedPayload, 100);
    },

    eventSelecionarOutrasOpcoesNegociacaoGTM(offer) {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "selecionou:option:outra-opcao-de-negociacao";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: this.buildPayloadOfferChooseOtherOptionsOfNegociation(offer),
        product_identify: this.offerInfo.contract,
        product_category: this.offerInfo.product,
        product_user_profile: "titular",
        brand: "porto",
        product: this.offerInfo.product,
        vertical: "bank",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventClickConfirmarGTM() {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:button:confirmar";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "outra-opcao-de-negociacao",
        product_identify: this.offerInfo.contract,
        product_category: this.offerInfo.product,
        product_user_profile: "titular",
        brand: "porto",
        product: this.offerInfo.product,
        vertical: "bank",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventCallbackNegociarFailureGTM(error) {
      console.log("errorrrrr: " + JSON.stringify(error.status));
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "solicitar-opcoes-pagamento:alert";
      const eventLabel = truncateString(
        "Não foi possível buscar ofertas, tente novamente mais tarde!",
        100
      );
      const eventCode = error.status;
      const eventErrorService = "";
      const eventAlertService = truncateString(error.data.message, 100);
      const eventAlert = "select_content";
      const eventAlertType = "erro";
      const eventData = {
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: eventLabel,
        alert_code: eventCode,
        error_service: eventErrorService,
        alert_service_message: eventAlertService,
        alert_event: eventAlert,
        alert_type: eventAlertType,
      };

      window.dataLayer.push({ event: "alert", ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    },

    eventClickToParcelarOuEmitirFaturaGTM(action, item) {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = `click:button:${action}`;

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: formatStringToLowerCase(item.product),
      };

      window.dataLayer.push({ ...eventData });
    },

    async goToSelectOptions() {
      const selectOptionsDiv = document.getElementById("selectOptions");
      if (selectOptionsDiv) {
        selectOptionsDiv.scrollIntoView();
        selectOptionsDiv.setAttribute("href", "#cardOfferUpdated");
      }
    },
  },
  mounted() {
    this.buildAndSetUserData(
      this.userDataStorage.cpfCnpj,
      this.userDataStorage.id,
      this.pageLocation,
      "prospostasContencioso"
    );
    this.$emit("updatePageView", this.pageLocation);
  },
  async created() {
    this.updateCustomData();
    this.ipUser = localStorage.getItem("ipUser");
    localStorage.setItem("currentPageName", this.$options.name);
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + "//" + this.host + this.path;
    this.offerInfo = this.$route.params.offerInfo;
    this.largura = window.screen.width;
    this.loadParcelamentoFatura();
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>

<style scoped>
/* @media (max-width: 500px) {
  .card {
    margin-right: 0;
  }
} */
.card-header {
  background-color: #f0f9e9;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  /* border-radius: 10px 10px 0 0; */
}
.best-offer {
  color: #8bc700;
  font-weight: bold;
  margin: 0;
}

select {
  width: 100%;
  padding: 5px;
  margin: 10px 0 5px 0;
  border-radius: 20px;
  border: 1px solid #0046c0;
  background-color: #fff;
  color: #4a2934;
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background-image: url("data:image/svg+xml;base64,<svg_code_here>"); */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  outline: none;
}

.btn-options.disabled-select {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6; /* Opcional, para dar feedback visual de desabilitado */
}
</style>
